.actionFooter {
  display: flex;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.9);
  border-top: 1px solid #d9d9d9;
  padding: 10px 31px 10px 265px;
  z-index: 9; }
  @media only screen and (max-width: 767px) {
    .actionFooter {
      padding: 10px 15px 10px 260px !important; } }
  .actionFooter.collapsed {
    padding: 10px 31px 10px 109px; }
    @media only screen and (max-width: 767px) {
      .actionFooter.collapsed {
        padding: 10px 15px !important; } }
  .actionFooter .isoLeft {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .actionFooter .isoLeft {
        margin: 0 20px 0 0; } }
  .actionFooter .isoRight {
    display: flex;
    align-items: center;
    margin-bottom: 0; }
  .actionFooter .ButtonGroup > Button:not(:last-child) {
    margin-right: 10px; }
