.App {
  min-height: 100vh;
  height: 100%; }
  .App .ant-layout {
    background-color: #FFF; }
  .App .header.ant-layout-header {
    background: linear-gradient(145deg, #40BD7D, #00aeef); }
  .App .logo {
    padding: 45px 0px;
    margin-bottom: 25px;
    background-color: #00a651;
    background-image: url("../../images/logo.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 235px;
    height: 34px; }
  .App .ant-layout-sider {
    background-color: #40BD7D;
    position: relative;
    z-index: 99;
    position: fixed;
    height: 100%; }
    .App .ant-layout-sider .ant-menu {
      background: none; }

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 55px;
  line-height: 55px; }

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #00a651; }

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item {
  border-left: 5px solid transparent; }

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  border-left-color: #FFF; }

.ContentWrapper {
  padding: 25px 25px; }

.ContentContainer {
  display: block;
  width: 100%;
  padding: 25px 25px; }
  .ContentContainer .Head {
    margin: 15px 0px; }
    .ContentContainer .Head .HeaderText {
      font-size: 20px; }
      .ContentContainer .Head .HeaderText i {
        margin-right: 10px; }
  .ContentContainer .ContentContainer-Container {
    background: #FFF;
    padding: 24px;
    min-height: 280px; }
    .ContentContainer .ContentContainer-Container.borderRight {
      border-right: 1px solid #d9d9d9; }

.table-clickable {
  cursor: pointer; }

.noUploadList .ant-upload-list-picture-card-container {
  display: none; }

.dropbox .ant-upload.ant-upload-select-picture-card {
  width: 100% !important; }

.delete:hover {
  cursor: pointer;
  color: #f5222d; }
