.login-container {
  background-image: url("../../images/login-background.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.login-wrapper {
  background-color: rgba(0, 0, 0, 0.3);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .login-wrapper .login-box {
    width: 90%;
    max-width: 400px;
    padding: 35px;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 7px 1px gray; }
    .login-wrapper .login-box .login-header h2, .login-wrapper .login-box .login-header span {
      color: rgba(0, 0, 0, 0.65);
      display: block;
      width: 100%;
      text-align: center; }
    .login-wrapper .login-box .form-wrapper {
      margin-top: 25px; }
      .login-wrapper .login-box .form-wrapper button {
        width: 100%; }
