.ant-badge-count {
  background: #40BD7D; }

tr.drop-over-downward td {
  border-bottom: 2px dashed #40BD7D; }

tr.drop-over-upward td {
  border-top: 2px dashed #40BD7D; }

.EmergencyBox {
  padding: 15px 10px; }
  .EmergencyBox .EmergencyTitle {
    display: block;
    padding: 5px 0px;
    margin-bottom: 10px;
    border-bottom: 1px solid #40BD7D; }
  .EmergencyBox .EmergencyContent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .EmergencyBox .EmergencyContent .EmergencyItem {
      user-select: none;
      cursor: pointer;
      flex: auto;
      padding: 10px;
      margin: 5px;
      text-align: center;
      width: 300px;
      border: 1px solid #d9d9d9;
      position: relative;
      word-wrap: break-word; }
      .EmergencyBox .EmergencyContent .EmergencyItem.selected {
        border-color: #40BD7D;
        background-color: #40BD7D;
        color: #FFF; }
